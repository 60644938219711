let operationIsCancelled = false
$(document).ready(function () {
  //setup before functions
  var typingTimer, //timer identifier
  doneTypingInterval = 400, //time in ms, 0.4s second for example
  btnSearch = $(".searchbar-autocomplete .btnSearch"), // Search button // currently not in use not sure if can be removed
  $input = $('#autocompleteHeader'), //on keyup, start the countdown // ids #autocompleteHeaderFixed #autocompleteHeader
  inputFixed = $('#autocompleteHeaderFixed'),
  totalChar;


  [$input, inputFixed].forEach(function(input) {
    input.on('keyup', function () {
      operationIsCancelled = false
      clearTimeout(typingTimer);
      totalChar = input.val().length
      if (input === $input){
        $(window).scrollTop(0);
      }
      if(totalChar > 2){
        if (input.val()) {
          typingTimer = setTimeout(() => doneTyping(input), doneTypingInterval);
          input.parent().parent().parent().addClass('searchbar-autocomplete--active');
            $('body').addClass('body-block');
        }
      }

      const searchResult = input.next(".result-autocomplete");
      if(totalChar == 2){
        searchResult.slideUp(150); //Hide result
        input.closest(".searchbar-autocomplete").removeClass('searchbar-autocomplete--active');
        $('body').removeClass('body-block');
      }

      if(input.val() == ""){
        btnSearch.fadeOut(150); //Hide search button
        searchResult.slideUp(150); //Hide result
        input.parent().parent().parent().removeClass('searchbar-autocomplete--active');
        $('body').removeClass('body-block');
      } else {
        btnSearch.fadeIn(150); //Show search button
      }
    })
  })
}); //user is "finished typing," do something

function doneTyping(input) {
  //do something
  getAutocompleteResults(input);
}

function getAutocompleteResults(input) {
  var $input = input,
  searchTerm = $input.val(),
  autocompleteServiceUrl = $input.data('autocomplete-service'),
  searchResult = $input.next(".result-autocomplete"),
  searchResultItems = searchResult.find(".items"),
  searchResultSeeAll = searchResult.find(".seeAll"),
  searchResultCategories = searchResult.find(".category"),
  searchbar = $(".searchbar"),
  btnSearch = $(".searchbar-autocomplete .btnSearch");
  clearSearch($input, searchResult, searchbar, btnSearch); //Clear search with click out and 'ESC' key

  if (searchTerm.length && autocompleteServiceUrl) {
    $.ajax({
      url: autocompleteServiceUrl,
      data: {
        searchTerm: searchTerm
      },
      beforeSend: function () {
        searchResultItems.empty(); //Clean items
        searchResultCategories.empty(); // Clean categories
        searchResultSeeAll.empty();
        searchResult.find('.result-autocomplete__text').hide()
        if (searchResultItems.find('.result-autocomplete__loading').length) return

        searchResultItems.append(`<div class="result-autocomplete__loading"></div>`)
        searchResult.slideDown(60)
      },
      success: function success(data) {
        if(data.result.length){
          searchResultItems.empty(); //Clean items
          searchResultCategories.empty(); // Clean categories

          if (operationIsCancelled) return

          $.each(data.result , function(key, value) {
            searchResultItems.append(`
              <a href="${value.url}" class="item">
                <div class="title">${value.title}</div>
              </a>
            `);
          });
          searchResultSeeAll.empty(); //Clean see all

          const seeAllResultsButton = createSeeAllResultsButton({
            link: data.searchPageUrlWithTerm,
            text: `Vis alle resultater`
          });

          searchResultSeeAll.append(seeAllResultsButton);
          for (let category of data.categories) {
            searchResultCategories.append(`
              <a class="item" href="${data.searchPageUrlWithTerm}&dv=${category}">
                <div class="title">${searchTerm} i<span class="title__bold">&nbsp;${category}</span></div>
              </a>
            `);
          }
          searchResult.find('.result-autocomplete__text').show()
          searchResult.slideDown(150); //Show result

          var itemsResult = $(".result-autocomplete .items .item, .result-autocomplete .seeAll a");

          itemsResult.each(function( index ) {
            $( this ).click(function(event){
              event.preventDefault();
              $input.val("");
              searchResult.slideUp(150);
              btnSearch.fadeOut(150);
              var thisUrl =  $( this ).attr("href");
              window.location.assign(thisUrl);
            });
          });
          input.parent().parent().parent().addClass('searchbar-autocomplete--active');
          $('body').addClass('body-block');
        }else{
          searchResult.slideUp(150); //Hide result
          input.parent().parent().parent().removeClass('searchbar-autocomplete--active');
          $('body').removeClass('body-block');
        }
      } // end success
    });
  }

}

function clearSearch(term,result,search,btn){
  $(document).click(function() {
    term.val("");
    result.slideUp(150); //Hide result
    btn.fadeOut(150); //Hide search button
    $(".searchbar-autocomplete").removeClass('searchbar-autocomplete--active');
    $('body').removeClass('body-block');
    operationIsCancelled = true
  });

  search.click(function(event) {
    event.stopPropagation();
  });

  $(document).keyup(function(e) {
    if (e.keyCode === 27 || e.key === 'Enter') {
      term.val("");
      result.slideUp(150); //Hide result
      btn.fadeOut(150); //Hide search button
      $(".searchbar-autocomplete").removeClass('searchbar-autocomplete--active');
      if (e.key !== 'Enter') $('body').removeClass('body-block');
      operationIsCancelled = true
    }
  });
}

// Adds a load while the /sok page is not yet ready
$(document).one('submit', '.searchbar-autocomplete form', function (event) {
  activateLoading();
  event.preventDefault();
  $(this).trigger('submit');
});

// GLAVA-611 - adding this block code to prevent the stuck spinner when pressing the back button to back to produkter page
window.addEventListener('pageshow', (event) => {
  if (event.persisted) {
    $('.search-result-loading').remove()
  } else {
    // do staff if needed
  }
});


function activateLoading () {
  $(".searchbar-autocomplete").removeClass('searchbar-autocomplete--active');
  $('body').append(`<div class="search-result-loading"><div class="loading"></div></div>`);
  $(".result-autocomplete").slideUp(150);
  $(".result-autocomplete").find(".items").empty();
  $(".result-autocomplete").find(".seeAll").empty();
  $(".result-autocomplete").find(".category").empty();
  $(".searchbar-autocomplete--active").removeClass('searchbar-autocomplete--active');
  $("input.searchbar-autocomplete__input").blur();
}

function createSeeAllResultsButton ({ link, text }) {
  const a = document.createElement('a');
  a.text = text;
  a.href = 'javascript:void(0);';
  a.onclick = function () {
    activateLoading();
    window.location.href = link
  }

  return a;
}
